<template>
    <BaseModal :variant=" showSignUp ? 'prospus-bg-blue' : 'prospus-bg-glory-red'">
        <template #header>
            <div class="prospus-modal__header--actions">
                <img src="/public/images/kaamfu-wide-k.png" class="prospus-logo" alt="kaamfu-logo-modal"/>
                <span class="prospus-close"
                      :class="`prospus-visibility-${isCanceled ? 'hide': 'show'}`"
                      @click="showCancelRegistrationOption">&times;</span>
            </div>
            <h3 class="prospus-title light">
                <template v-if="isCanceled">
                    Are you sure you want to cancel your Kaamfu registration?
                </template>
                <template v-else-if="showSignUp">
                    We just sent a validation code to your email.
                    <p>Enter the code below:</p>
                </template>
                <template v-else>
                    You still need to validate your email account.
                </template>
            </h3>
        </template>
        <div v-if="isCanceled" class="prospus-flex prospus-flex-column prospus-flex-end prospus-my-auto">
            <button class="prospus-btn prospus-b-white-2" @click="showOTP"> No, do not cancel my registration</button>
            <button class="prospus-btn prospus-b-none prospus-mt-20" @click="$emit('reset')">Yes, please cancel my
                registration
            </button>
        </div>
        <div v-else class="prospus-otp-wrap">
            <form id="prospus-otp-form" class="prospus-form"
                  @submit.prevent="validateOTP"
                  @keyup.enter.prevent="validateOTP">
                <!-- <span>OTP</span> -->
                <div class="input-wrapper prospus-has-validation-check password-validation">
                    <div class="input-wrapper__inner">
                        <button
                            class="hint-mode"
                            tabindex="-1"
                            type="button"
                            @mouseover="showTooltip = true"
                            @mouseout="showTooltip = false">
                            <span v-if="otpValue.length == 0" class="material-icons"> pin </span>
                            <span v-else-if="isSuccessful" class="material-icons"> task_alt </span>
                            <span v-else class="material-icons">info_outline</span>
                        </button>
                        <OtpInput
                            ref="refInputOTP"
                            class="prospus-otp-input-wrap"
                            :num-inputs="5"
                            seperator=""
                            :should-auto-focus="true"
                            :is-input-num="true"
                            @on-change="onOtpEntered"
                            @on-complete="onOtpEntered"
                            @focusin="showTooltip = true"
                            @focusout="showTooltip=false">
                        </OtpInput>
                        <a class="prospus-link prospus-ml-20 prospus-mt-10 prospus-text-xs prospus-fs-i"
                           @click="clearOTP">
                            CLEAR
                        </a>
                    </div>
                    <!--Show tooltip
                        Case 1: When user start typing otp
                        Case 2: otp should be valid
                        Case 3: User should entered atleast one charter
                    -->
                    <Tooltip
                        v-if="(error_message && showTooltip && ( otpValue.length != 0 && otpValue.length < 5)) || isOTPVerifiedInvalid">
                        <ul>
                            <li>
                                <span class="material-icons">close</span>
                                {{error_message}}
                            </li>
                        </ul>
                    </Tooltip>
                </div>
                <div class="prospus-otp-check prospus-flex">
                    <div class="prospus-otp-resent">
                        <span class="prospus-block prospus-pt-10"
                              :class="[isOTPResent ? 'prospus-visibility-show' :'prospus-visibility-hide' ]">The code has been resent. <br/> Please check your spam folder.</span>
                    </div>
                    <div class="prospus-resend-otp">
                        <span class="prospus-btn prospus-b-none prospus-mr-10" @click="resentOTP">Resend code</span>
                    </div>
                    <div class="prospus-submit">
                        <button class="prospus-signup btn-loader"
                                :class="{ 'btn-white prospus-btn--disabled' : showLoader || isSuccessful || !isValidOtpLength }"
                                type="submit"
                                :disabled="isRequestInProgress">
                            <div v-if="showLoader" class="prospus-inline-loader"></div>
                            <span v-else>Verify</span>
                        </button>
                    </div>
                </div>
                <div class="prospus-flex prospus-cancel-registration">
                    <button class="prospus-btn prospus-b-none" @click="showCancelRegistrationOption">Cancel my
                        registration
                    </button>
                </div>
            </form>
        </div>
    </BaseModal>
</template>
<script>
import axios from 'axios'
import BaseModal from '../Common/BaseModal.vue'
import OtpInput from '../../OTP/Input.vue'
import Tooltip from '../Common/Tooltip.vue'
import {useLogin} from "../../Stores/login";

export default {
    components: {
        BaseModal,
        OtpInput,
        Tooltip
    },
    props: {
        showSignUp: {
            type: Boolean,
            Default: false
        }
    },
    data() {
        return {
            otpError: '',
            isOTPResent: false,
            otpValue: '',
            showLoader: false,
            isRequestInProgress: false,
            isSuccessful: false,
            showTooltip: false,
            isCanceled: false,
            error_message: '',
            isOTPVerifiedInvalid: false,
        }
    },
    computed: {
        isValidOtpLength() {
            return this.otpValue.length == 5
        },
        isShowTooltip() {
            return this.otpValue.length > 0
        }
    },

    methods: {
        async resentOTP() {
            try {
                //requestStarted()
                await axios.post("/resend-otp", {token: useLogin().getToken()});
                this.isOTPResent = true
                this.clearOTP()
                //requestProcessed();
            } catch (err) {
                //requestProcessed();
            }
        },
        clearOTP() {
            this.$refs.refInputOTP.clearInput();
        },
        async validateOTP() {
            this.error_message = '';
            if (!this.isValidOtpLength)
                return

            try {

                this.requestStarted()
                //let user_id = localStorage.getItem('kaamfu_user_id')
                await axios.post('/otp', {
                    otp: this.otpValue,
                    user_id: useLogin().getUserId(),
                    token: useLogin().getToken()
                })
                this.isSuccessful = true
                this.goToDashBoard()
                this.requestProcessed()
            } catch (err) {
                this.requestProcessed();
                this.error_message = err?.response?.data?.message
                this.showTooltip = this.isOTPVerifiedInvalid = true
            }
        },
        requestStarted() {
            this.isSuccessful = this.otpError = false
            this.isRequestInProgress = this.showLoader = true;
        },
        requestProcessed() {
            this.isRequestInProgress = false
            this.showLoader = false;
        },
        goToDashBoard() {
            window.location.reload()
            //window.location.href = `${window.location.origin}/`
        },

        onOtpEntered(value) {
            this.otpValue = value;
            //console.log("otp entered", value)
        },
        showCancelRegistrationOption() {
            this.isCanceled = true
        },
        showOTP() {
            this.isCanceled = false
        }
    },

}
</script>
