<template>
    <BaseModal>
        <template #header>
            <div class="prospus-modal__header--actions">
                <img
                    src="/public/images/kaamfu-wide-k.png"
                    class="prospus-logo"
                    alt="kaamfu-logo-modal"
                />
                <span class="prospus-close" @click="$emit('close')"
                    >&times;</span
                >
            </div>
            <h3 class="prospus-title">Create your new password.</h3>
        </template>
        <div>
            <form id="prospus-sign-up" class="prospus-form" @submit.prevent>
                <div class="prospus-email-wrap">
                    <label for="email">Email</label>
                    <div
                        class="input-wrapper prospus-has-validation-check"
                        :class="loginErrorMessages.email ? 'has-error' : ''"
                    >
                        <div class="input-wrapper__inner">
                            <button
                                type="button"
                                :class="[
                                    loginErrorMessages.email ? 'hint-mode' : '',
                                ]"
                            >
                                <span class="material-symbols-rounded">{{
                                    emailValidateIcon
                                }}</span>
                            </button>
                            <input
                                ref="refEmail"
                                v-model="loginForm.email"
                                type="email"
                                name="email"
                                title="email"
                                autofocus
                                autocomplete="false"
                                @focusin="loginErrorMessages.email = true"
                                @focusout="loginErrorMessages.email = false"
                            />
                        </div>
                    </div>
                </div>

                <div class="prospus-password-wrap">
                    <label for="password"> Password </label>
                    <div
                        class="input-wrapper prospus-has-validation-check password-validation"
                        :class="loginErrorMessages.password ? 'has-error' : ''"
                    >
                        <div class="input-wrapper__inner">
                            <button
                                :class="showTooltip ? 'hint-mode' : ''"
                                tabindex="-1"
                                type="button"
                                @mouseover="showTooltip = true"
                                @mouseout="showTooltip = false"
                            >
                                <span
                                    v-if="isValidPassword"
                                    class="material-symbols-rounded"
                                    >task_alt</span
                                >
                                <span
                                    v-else-if="isEmptyPasswordAndNotFocused"
                                    class="material-symbols-rounded"
                                    >lock</span
                                >
                                <span v-else class="material-icons"
                                    >info_outline</span
                                >
                            </button>
                            <input
                                ref="refInputPwd"
                                v-model="loginForm.password"
                                class="prospus-password"
                                :type="`${showPassword ? 'text' : 'password'}`"
                                name="password"
                                title="password"
                                autocomplete="new-password"
                                @focusin="showTooltip = true"
                                @focusout="showTooltip = false"
                            />
                        </div>
                        <PasswordCriteriaTooltip
                            v-if="showTooltip"
                            :password="loginForm.password"
                            @validity="updatePwdValidity"
                        />
                    </div>
                    <div class="prospus-password-eyes">
                        <span
                            v-if="showPassword"
                            class="material-icons-round prospus-password-eye-open"
                            @click="togglePwdVisiblity"
                            >visibility</span
                        >
                        <span
                            v-else
                            class="material-icons-round prospus-password-eye-closed"
                            @click="togglePwdVisiblity"
                            >visibility_off</span
                        >
                    </div>
                </div>
                <div class="prospus-password-wrap">
                    <label for="confirm_password"> Confirm Password </label>
                    <div
                        class="input-wrapper prospus-has-validation-check password-validation"
                        :class="
                            loginErrorMessages.password_confirmation
                                ? 'has-error'
                                : ''
                        "
                    >
                        <div class="input-wrapper__inner">
                            <button
                                :class="
                                    loginErrorMessages.password_confirmation
                                        ? 'hint-mode'
                                        : ''
                                "
                                type="button"
                                tabindex="-1"
                            >
                                <span class="material-symbols-rounded">{{
                                    passwordConfirmValidateIcon
                                }}</span>
                            </button>
                            <input
                                ref="refInputConfirmPwd"
                                v-model="loginForm.password_confirmation"
                                class="prospus-password"
                                :type="`${showConfirmPassword ? 'text' : 'password'}`"
                                name="confirm_password"
                                title="confirm_password"
                                @focusin="
                                    loginErrorMessages.password_confirmation = true
                                "
                                @focusout="
                                    loginErrorMessages.password_confirmation = false
                                "
                            />
                            <div class="prospus-password-eyes">
                                <span
                                    v-if="showConfirmPassword"
                                    class="material-icons-round prospus-password-eye-open"
                                    @click="toggleConfirmPwdVisiblity"
                                    >visibility</span
                                >
                                <span
                                    v-else
                                    class="material-icons-round prospus-password-eye-closed"
                                    @click="toggleConfirmPwdVisiblity"
                                    >visibility_off</span
                                >
                            </div>
                        </div>
                        <PasswordCriteriaTooltip
                            v-if="
                                loginErrorMessages.password_confirmation &&
                                !isConfirmValid
                            "
                            :password="loginForm.password_confirmation"
                            :confirm="true"
                            @validity="updateConfirmPwdValidity"
                        />
                    </div>
                </div>
                <div class="prospus-submit prospus-flex-center-y">
                    <!-- <span v-if="loginError">
                       {{ loginErrorText
                               ? loginErrorText
                               : 'The provided details are invalid'
                       }}
                   </span> -->
                    <a
                        v-if="!isRequestInProgress"
                        class="prospus-btn prospus-b-none prospus-mr-10"
                        @click.stop="$emit('close')"
                    >
                        Cancel
                    </a>
                    <!--<input @click="signUp"-->
                    <!--    class="prospus-signup btn-loader"-->
                    <!--    :class="{ 'btn-white prospus-btn--disabled' : isRequestInProgress || !isFormDataValid  }"-->
                    <!--    type="button"-->
                    <!--    value="Submit"-->
                    <!--    :disabled="isRequestInProgress || isLoginDone">-->

                    <button
                        type="button"
                        class="view-signin prospus-btn btn-lg btn-loader"
                        :class="{
                            'btn--white prospus-btn--disabled':
                                isRequestInProgress || !isFormDataValid,
                            'btn--red border-white': isConfirmValid,
                            'btn--white text--red no-border': !isConfirmValid,
                        }"
                        :disabled="isRequestInProgress || isLoginDone"
                        @click="signUp"
                    >
                        <div
                            v-if="showLoader"
                            class="prospus-inline-loader"
                        ></div>
                        <span v-else>Submit</span>
                        <!--<span>Submit</span>-->
                    </button>
                </div>
            </form>
        </div>
    </BaseModal>
</template>
<script setup>
import { reactive, ref, onBeforeMount, onMounted, computed } from "vue";
import axios from "axios";
import BaseModal from "../Common/BaseModal.vue";
import PasswordCriteriaTooltip from "../Common/PasswordCriteriaTooltip.vue";

const emitter = defineEmits();

onBeforeMount(() => {
    const params = new URL(location).searchParams;
    loginForm.token = params.get("token");
    setEmailFocued();
});

onMounted(() => {
    emitter("on-load");
});

const isEmailValid = computed(() =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(loginForm.email),
);
const isPasswordValid = computed(
    () =>
        loginForm.password.length >= 8 &&
        (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(loginForm.password) ||
            /[0-9]/.test(loginForm.password)),
);
const isConfirmValid = computed(
    () =>
        loginForm.password.length > 0 &&
        loginForm.password == loginForm.password_confirmation,
);

const emailValidateIcon = computed(() => {
    if (loginForm.email === "") return "mail";
    else if (verifyEmail()) return "task_alt";
    else return "highlight_off";
});

const isEmptyPasswordAndNotFocused = computed(
    () => !showTooltip.value && loginForm.password.length == 0,
);
const passwordConfirmValidateIcon = computed(() => {
    if (loginForm.password_confirmation.length === 0) return "lock";

    if (loginForm.password == loginForm.password_confirmation)
        return "task_alt";

    return "highlight_off";
});

const isFormDataValid = computed(
    () => isEmailValid.value && isPasswordValid.value && isConfirmValid.value,
);

const refEmail = ref(null);
const refInputPwd = ref(null);
const refInputConfirmPwd = ref(null);

const loginError = ref("");
const loginErrorText = ref("");
const otpError = ref("");
const otpValue = ref("");
const loginForm = reactive({
    token: "",
    email: "",
    password: "",
    password_confirmation: "",
});
const loginErrorMessages = reactive({
    email: false,
    password: false,
    password_confirmation: false,
});
const isValidPassword = ref(false);
const showOTPInput = ref(false);
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const isLoginDone = ref(false);
const isRequestInProgress = ref(false);
const showTooltip = ref(false);
const showLoader = ref(false);

const currentView = ref("sign-in");

async function signUp() {
    if (isInputInvalid())
        return (loginErrorText.value = "Form filled with invalid data");

    try {
        showLoader.value = true;
        requestStarted();
        loginError.value = false;
        await axios.post("/reset-password", loginForm);
        showOTPInput.value = true;
        isLoginDone.value = true;
        goToHomePage();
        requestProcessed();
    } catch (err) {
        //console.log("error while registering", )
        update422ErrorResponseMessage(err.response);
        requestProcessed();
        loginError.value = true;
        showLoader.value = false;
    }
}

function update422ErrorResponseMessage(response) {
    if (response?.status == 422) loginErrorText.value = response?.data?.message;
}

function requestStarted() {
    loginErrorText.value = "";
    isRequestInProgress.value = true;
}

function requestProcessed() {
    isRequestInProgress.value = false;
}

function goToHomePage() {
    window.location.href = `${window.location.origin}`;
}

function handleOnComplete(value) {
    otpValue.value = value;
    //console.log("otp entered", value)
}

function setEmailFocued() {
    const timeoutHandler = setTimeout(() => {
        refEmail.value.focus();
        loginErrorMessages.email = true;
        clearTimeout(timeoutHandler);
    }, 500);
}

function verifyEmail() {
    if (isEmailValid.value) return true;

    loginErrorMessages.email = true;
    return false;
}

function verifyPassword() {
    isValidPassword.value = showTooltip.value = false;

    if (isPasswordValid.value) return (isValidPassword.value = true);

    //loginErrorMessages.password = 'Input a valid password';
    showTooltip.value = true;

    return false;
}
function verifyConfirmPasswordSameAsPassword() {
    if (
        !loginErrorMessages.password &&
        loginForm.password_confirmation === loginForm.password
    )
        return true;
    loginErrorMessages.confirm_password =
        "Confirm Password not matching Password";
}

function resetErrors() {
    loginErrorMessages.value = {
        email: "",
        password: "",
    };
}

function isInputInvalid() {
    resetErrors();

    verifyEmail();
    verifyPassword();
    verifyConfirmPasswordSameAsPassword();

    return (
        loginErrorMessages.email ||
        loginErrorMessages.password ||
        loginErrorMessages.confirm_password
    );
}

function updatePwdValidity(isValid) {
    isValidPassword.value = isValid;
}

function togglePwdVisiblity() {
    showPassword.value = !showPassword.value;
    showTooltip.value = true;
    let timeout = setTimeout(() => {
        refInputPwd.value.focus();
        refInputPwd.value.setSelectionRange(
            loginForm.password.length,
            loginForm.password.length,
        );
        clearTimeout(timeout);
    }, 500);
}

function toggleConfirmPwdVisiblity() {
    showConfirmPassword.value = !showConfirmPassword.value;
    loginErrorMessages.password_confirmation = true;

    let timeout = setTimeout(() => {
        refInputConfirmPwd.value.focus();
        refInputConfirmPwd.value.setSelectionRange(
            loginForm.password_confirmation.length,
            loginForm.password_confirmation.length,
        );
        clearTimeout(timeout);
    }, 500);
}
</script>
