<template>
    <div id="prospus-client-auth">
        <PermissionError v-if="show_permission_error_page"></PermissionError>
        <template v-else>
            <AuthDashBoard/>
            <div id="prospus-chat-auth">
                <AuthPanel
                    :show-sign-up="showSignUp"
                    :show-reset-pwd="showResetPassword"
                    :invitation-code="invitation_code"
                    @sign-up="onShowSignUp"
                    @sign-in="onShowSignIn"
                >
                </AuthPanel>
            </div>
        </template>

    </div>
</template>

<script>
import AuthPanel from './Modules/Auth/AuthPanel.vue'
import AuthDashBoard from './Modules/Auth/AuthDashBoard.vue'
import PermissionError from "./Modules/Auth/PermissionError.vue";

export default {

    components: {
        PermissionError,
        AuthPanel,
        AuthDashBoard
    },
    data() {
        return {
            showSignUp: false,
            showResetPassword: false,
            show_permission_error_page: false
        }
    },

    computed: {
        isAuthenticated() {
            let token = localStorage.getItem('kaamfu_user_token')



            return (token && token.length > 0)
        },
        invitation_code() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const invitationCode = urlParams.get('invitation-code');

            return invitationCode
        }
    },

    mounted() {
        const params = (new URL(location)).searchParams;
        if (params.get('action') == 'reset_password') {
            this.showResetPassword = true
        }
        this.show_permission_error_page = this.hasPath()

        if (this.invitation_code) {
            this.showSignUp = true
        }


    },

    methods: {
        onShowSignIn() {
            this.showSignUp = false
        },
        onShowSignUp() {
            this.showSignUp = true
        },
        hasPath() {
            const parsedURL = new URL(location.href);
            return parsedURL.pathname !== "/" && parsedURL.pathname.length === 9;
        }
    }
}
</script>
