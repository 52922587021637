<template>
    <div
        class="prospus-sign-up-wrap-main"
        :class="showSignUp ? 'view-signup' : 'view-signin'"
    >
        <div class="prospus-switch-view">
            <!--            <button
                            v-if="chatMode === 'fixed'"
                            @click="$emit('show-chat')"
                            class="prospus-border-2-white prospus-btn prospus-btn&#45;&#45;link back-to-chat prospus-mr-10">
                            <span class="material-symbols-rounded">navigate_before</span>
                            <span class="button-text">Back to chat</span>
                        </button>-->
            <button
                class="prospus-border-2-white prospus-btn prospus-btn--link sign-in prospus-mr-10"
                :class="showSignUp ? '' : 'active'"
                @click="handleSignInAndToggleView"
            >
                Sign in
            </button>
            <button
                class="prospus-border-2-white prospus-btn prospus-btn--link sign-up"
                :class="showSignUp ? 'active' : ''"
                @click="handleSignUpAndToggleView"
            >
                Sign up
            </button>
        </div>
        <div class="prospus-sign-up-wrap prospus-scrollbar">
            <div class="prospus-chat-logo">
                <img
                    v-if="showSignUp"
                    src="/public/images/kaamfu-text-logo.png"
                    alt="sign up Logo"
                />
                <img
                    v-else
                    src="/public/images/kaamfu-text-logo.png"
                    alt="sign in Logo"
                />
            </div>
            <div class="prospus-signup-description">
                <p>
                    {{ showSignUp ? "Sign up" : "Sign in" }} and get live
                    support in 30 seconds.
                </p>
            </div>
            <form
                id="prospus-sign-up"
                class="prospus-form pb-100"
                autocomplete="off"
                @submit.prevent="onSubmit"
                @keyup.enter.prevent="onSubmit"
            >
                <div class="prospus-email-wrap">
                    <label for="email">Email</label>
                    <div
                        class="input-wrapper prospus-has-validation-check"
                        :class="loginErrorMessages.email ? 'has-error' : ''"
                    >
                        <div class="input-wrapper__inner">
                            <button
                                type="button"
                                :class="[
                                    loginErrorMessages.email ? 'hint-mode' : '',
                                ]"
                            >
                                <span
                                    class="material-symbols-rounded"
                                    :class="`${showSignUp ? 'sign-up' : 'sign-in'}`"
                                >
                                    {{ emailValidateIcon }}
                                </span>
                            </button>
                            <input
                                ref="refInputEmail"
                                v-model="loginForm.email"
                                tabindex="1"
                                type="email"
                                name="email"
                                title="email"
                                autofocus
                                autocomplete="false"
                                @focusin="loginErrorMessages.email = true"
                                @focusout="loginErrorMessages.email = false"
                            />
                        </div>
                    </div>
                </div>
                <div class="prospus-password-wrap">
                    <label for="password"> Password </label>
                    <div
                        class="input-wrapper prospus-has-validation-check password-validation"
                        :class="loginErrorMessages.password ? 'has-error' : ''"
                    >
                        <div class="input-wrapper__inner">
                            <button
                                :class="showTooltip ? 'hint-mode' : ''"
                                type="button"
                                @mouseover="showTooltip = true"
                                @mouseout="showTooltip = false"
                            >
                                <span
                                    v-if="isValidPassword"
                                    :class="`${showSignUp ? 'sign-up' : 'sign-in'}`"
                                    class="material-symbols-rounded"
                                    >task_alt</span
                                >
                                <span
                                    v-else-if="isEmptyPasswordAndNotFocused"
                                    :class="`${showSignUp ? 'sign-up' : 'sign-in'}`"
                                    class="material-symbols-rounded"
                                    >lock</span
                                >
                                <span
                                    v-else
                                    :class="`${showSignUp ? 'sign-up' : 'sign-in'}`"
                                    class="material-icons"
                                    >info_outline</span
                                >
                            </button>
                            <input
                                ref="refInputPwd"
                                v-model="loginForm.password"
                                tabindex="2"
                                class="prospus-password"
                                :type="`${showPassword ? 'text' : 'password'}`"
                                name="password"
                                title="password"
                                autocomplete="new-password"
                                @focusin="showTooltip = true"
                                @focusout="showTooltip = false"
                            />
                        </div>

                        <PasswordCriteriaTooltip
                            v-if="showTooltip"
                            :password="loginForm.password"
                            @validity="updatePwdValidity"
                        />
                    </div>
                    <div class="prospus-password-eyes">
                        <span
                            v-if="showPassword"
                            class="material-icons-round prospus-password-eye-open"
                            @click="toggleVisiblity"
                            >visibility</span
                        >
                        <span
                            v-else
                            class="material-icons-round prospus-password-eye-closed"
                            @click="toggleVisiblity"
                            >visibility_off</span
                        >
                    </div>
                </div>

                <div
                    class="prospus-invitation-wrap"
                    v-if="showSignUp && invitationCode"
                >
                    <label for="email">Invitation Code</label>
                    <div
                        class="input-wrapper prospus-has-validation-check"
                        :class="loginErrorMessages.code ? 'has-error' : ''"
                    >
                        <div class="input-wrapper__inner">
                            <button type="button">
                                <span class="material-symbols-rounded">
                                    keyboard
                                </span>
                            </button>
                            <input
                                ref="refInputEmail"
                                :value="invitationCode"
                                tabindex="3"
                                type="text"
                                class="prospus-invitation"
                                name="invitationCode"
                                title="invitationCode"
                                autofocus
                                readonly
                                autocomplete="false"
                                @focusin="loginErrorMessages.code = true"
                                @focusout="loginErrorMessages.code = false"
                            />
                        </div>
                    </div>
                </div>

                <div class="prospus-submit prospus-flex-center-y">
                    <template v-if="!showSignUp">
                        <a
                            class="prospus-link prospus-mr-10"
                            @click="showForgotPwd = true"
                        >
                            Forgot password
                        </a>
                        <button
                            type="button"
                            :class="{
                                'prospus-signup btn-loader': showSignUp,
                                'prospus-signin btn-loader': !showSignUp,
                                'btn-dark': !(
                                    showLoader ||
                                    isLoginDone ||
                                    !isFormDataValid
                                ),
                                'btn-white prospus-btn--disabled':
                                    showLoader ||
                                    isLoginDone ||
                                    !isFormDataValid,
                            }"
                            :disabled="isRequestInProgress || isLoginDone"
                            @click="signIn"
                        >
                            <div
                                v-if="!isLoginDone && showLoader"
                                class="prospus-inline-loader"
                            ></div>
                            <span v-else>Sign In</span>
                        </button>
                    </template>
                    <button
                        v-else
                        class="prospus-signup btn-loader"
                        :class="{
                            'btn-dark': !(
                                showLoader ||
                                isLoginDone ||
                                !isFormDataValid
                            ),
                            'btn-white prospus-btn--disabled':
                                showLoader || isLoginDone || !isFormDataValid,
                        }"
                        type="button"
                        :disabled="isRequestInProgress || isLoginDone"
                        @click="signUp"
                    >
                        <div
                            v-if="!isLoginDone && showLoader"
                            class="prospus-inline-loader"
                        ></div>
                        <span v-else>Sign Up</span>
                    </button>
                </div>
                <span
                    v-if="loginError"
                    class="prospus-inline-flex warning-existing-user prospus-mt-20"
                >
                    <span class="material-icons-round prospus-search"
                        >warning</span
                    >
                    <span class="error-message">
                        {{
                            loginErrorText
                                ? loginErrorText
                                : "The provided credentials do not match our records"
                        }}
                    </span>
                </span>
            </form>
        </div>
        <Transition v-if="pwdResetSuccessful" duration="550" name="nested">
            <ResetPwdSuccess
                @close="
                    $emit('close-pwd-success');
                    setFocusOnEmail;
                "
            ></ResetPwdSuccess>
        </Transition>
        <Transition v-if="showForgotPwd" duration="550" name="nested">
            <ForgotPassword @close="showForgotPwd = false"></ForgotPassword>
        </Transition>
        <Transition v-if="showResetPwd" duration="550" name="nested">
            <ResetPassword
                @on-load="$emit('sign-in')"
                @close="showResetPwd = false"
            >
            </ResetPassword>
        </Transition>
        <Transition v-if="showOtpModal" duration="550" name="nested">
            <OtpModal
                :show-sign-up="showSignUp"
                @cancel="registrationCanceled"
                @reset="enableUserToUpdateAndSignup"
            >
            </OtpModal>
        </Transition>
    </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, computed, watch } from "vue";
import ForgotPassword from "../Components/Modal/ForgotPassword.vue";
import ResetPassword from "../Components/Modal/ResetPassword.vue";
import OtpModal from "../Components/Modal/OtpModal.vue";
import ResetPwdSuccess from "../Components/Modal/ResetPwdSuccess.vue";
import PasswordCriteriaTooltip from "../Components/Common/PasswordCriteriaTooltip.vue";
//import Chatter from '../Helpers/communications'
import { AGENT } from "../Constants/generic";
import axios from "axios";
import { debounce } from "lodash";
import { useLogin } from "../Stores/login";
import HttpStatusCodes from "../../../../Admin/js/Constants/http";

const props = defineProps({
    showChatPanel: {
        type: Boolean,
        default: true,
    },
    showSignUp: {
        type: Boolean,
        default: true,
    },
    showResetPwd: {
        type: Boolean,
        default: false,
    },
    pwdResetSuccessful: {
        type: Boolean,
        default: false,
    },
    agent: {
        type: Object,
        default: AGENT,
    },
    invitationCode: {
        type: String,
        default: null,
    },
});

/** Get screen size */
const screenSize = ref(0);

const emitter = defineEmits(["sign-in", "sign-up"]);

const { showSignUp, showResetPwd, pwdResetSuccessful, invitationCode } =
    toRefs(props);

const refInputEmail = ref(null);
const refInputPwd = ref(null);

const loginError = ref("");
const loginErrorText = ref("");
const loginForm = reactive({
    email: "",
    password: "",
});
const loginErrorMessages = reactive({
    email: false,
    password: false,
});

const showOTPInput = ref(false);
const showPassword = ref(false);
const showOtpModal = ref(false);
const isLoginDone = ref(false);
const isRequestInProgress = ref(false);
const showForgotPwd = ref(false);
const showTooltip = ref(false);
const isValidPassword = ref(false);
const showLoader = ref(false);
const showChatLoader = ref(false);
const expandDetails = ref(false);
const flagDrawer = ref(false);
const debouncedDrawer = ref(false);

//const showResetPwd = ref(false)

// const closeChatApp = () => {
//     document.getElementById("mobile-side-drawer").style.right = "-300%";
//     const agentDetailsSection = document.querySelector(
//         ".prospus-expanded-details",
//     );
//     setAgentDetailsStyleByViewport(screenSize.value);
// };

const emailValidateIcon = computed(() => {
    if (loginForm.email === "") return "mail";
    else if (verifyEmail()) return "task_alt";
    else return "highlight_off";
});

const isEmailValid = computed(() =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(loginForm.email),
);
const isPasswordValid = computed(
    () =>
        loginForm.password.length >= 8 &&
        (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(loginForm.password) ||
            /[0-9]/.test(loginForm.password)),
);
const isEmptyPasswordAndNotFocused = computed(
    () => !showTooltip.value && loginForm.password.length == 0,
);
const isFormDataValid = computed(
    () => isEmailValid.value && isPasswordValid.value,
);
// const footerText = computed(
//     () =>
//         `Powered by<img class="prospus-logo-footer" src="/public/images/prospus-dark-logo.png" alt="prospus logo" />Prospus`,
// );

//const { chatMode} = Chatter()

// WATCHERS
// watch(() => debouncedDrawer, (val) => {
//         // onDebouncedDrawer(val)
//     },
//     {deep: true}
// )

watch(
    () => showSignUp,
    (obj) => {
        if (obj.value === null) {
            chatIsLoaded(true);
            flagDrawer.value = false;
        } else {
            chatIsLoaded(false);
        }
    },
);

watch(
    () => showResetPwd,
    (val) => {
        console.log("showResetPwd", val);
    },
);

function resetErrors() {
    loginErrorMessages.value = {
        email: false,
        password: false,
    };
}

function verifyEmail() {
    if (isEmailValid.value) return true;

    loginErrorMessages.email = true;
    return false;
}

function verifyPassword() {
    isValidPassword.value = showTooltip.value = false;

    if (isPasswordValid.value) return (isValidPassword.value = true);

    //loginErrorMessages.password = 'Input a valid password';
    showTooltip.value = true;

    return false;
}

function isInputInvalid() {
    resetErrors();

    return !verifyEmail() || !verifyPassword();
}

async function onSubmit() {
    return showSignUp.value ? signUp() : signIn();
}

async function signIn() {
    if (isInputInvalid()) return;

    try {
        showLoader.value = true;
        requestStarted();
        loginError.value = false;

        const { data } = await axios.post("/login", loginForm);
        useLogin().setUserId(data.data.user_id);
        //localStorage.setItem('kaamfu_user_id', data.user_id)
        //localStorage.setItem('kaamfu_user_token', data.user_id)

        isLoginDone.value = true;
        requestProcessed();
        if (data.data.is_verified) {
            goToURL(data.data.redirection_url);
        } else {
            useLogin().setToken(data.data.token);
            showOtpModal.value = true;
        }
    } catch (err) {
        updateErrorResponseMessage(err.response);
        requestProcessed();
        loginError.value = true;
    }
}

async function signUp() {
    if (isInputInvalid()) return;

    loginForm.invitation_code = invitationCode;

    try {
        requestStarted();
        loginError.value = false;
        const { data } = await axios.post("/register", loginForm);
        useLogin().setUserId(data.data?.user_id);
        useLogin().setToken(data.data?.token);
        showOTPInput.value = true;
        isLoginDone.value = true;
        requestProcessed();
        //$('.prospus-otp-wrap').fadeIn();
        showOtpModal.value = true;
    } catch (err) {
        //console.log("error while registering", )
        updateErrorResponseMessage(err.response);
        requestProcessed();
        loginError.value = true;
        showLoader.value = false;
    }
}

function updateErrorResponseMessage(response) {
    if (
        response?.status === HttpStatusCodes.UNAUTHORIZED ||
        response?.status === HttpStatusCodes.BAD_REQUEST
    ) {
        loginErrorText.value = response?.data?.message;
    }

    if (response?.data?.already_registered) emitter("signIn");
}

function requestStarted() {
    loginErrorText.value = "";
    isRequestInProgress.value = true;
    showLoader.value = true;
}

function requestProcessed() {
    isRequestInProgress.value = false;
    showLoader.value = false;
}

function goToURL(url = null) {
    if (url) {
        window.location.href = url;
        return;
    }

    window.location.reload();
}

function toggleView() {
    isLoginDone.value = false;
    loginForm.email = "";
    loginForm.password = "";
    loginError.value = false;
    loginErrorText.value = "";
    showOTPInput.value = false;
    setFocusOnEmail();
}

function setFocusOnEmail() {
    refInputEmail.value.focus();
    loginErrorMessages.email = true;
}

function toggleVisiblity() {
    showPassword.value = !showPassword.value;
    showTooltip.value = true;
    let timeout = setTimeout(() => {
        refInputPwd.value.focus();
        refInputPwd.value.setSelectionRange(
            loginForm.password.length,
            loginForm.password.length,
        );
        clearTimeout(timeout);
    }, 500);
}

function updatePwdValidity(isValid) {
    isValidPassword.value = isValid;
}

function enableUserToUpdateAndSignup() {
    showOtpModal.value = false;
    showOTPInput.value = false;
    isLoginDone.value = false;
    loginError.value = false;
    loginErrorText.value = "";
}

function registrationCanceled() {
    showOtpModal.value = false;
    toggleView();
    loginForm.email = loginForm.password = "";
}

function chatIsLoaded(val) {
    if (val !== showChatLoader.value) {
        if (val) {
            let timeout = setTimeout(() => {
                emitter("established-connection", true);
                emitter("on-chat-load", true);
                showChatLoader.value = true;
                clearTimeout(timeout);
            }, 2000);
        } else {
            // showChatLoader.value = false
            // emitter('on-chat-load', false)
        }
    }
}

function onExpandDetails(val) {
    expandDetails.value = val;
    if (val) flagDrawer.value = true;

    if (screenSize.value <= 1024) {
        setAgentDetailsStyleByViewport(screenSize.value);
        if (screenSize.value <= 449) {
            document.getElementById("mobile-side-drawer").style.right =
                "calc(100% - 24px)";
        } else if (screenSize.value <= 1024) {
            document.getElementById("mobile-side-drawer").style.right = "436px";
        }
    }
}

// function handleFocus() {
// do something here
// console.log('1. Handles focus in parent...')
// debouncedDrawer.value = true
// onDebouncedDrawer(true)
// }
// function handleFocusOut() {
// do something here
// console.log('1. Handles focus out in parent...', expandDetails.value)
// debouncedDrawer.value = false
// onDebouncedDrawer(false)
// }

function onDrawerEvent(val) {
    debouncedDrawer.value = val;
    onDebouncedDrawer(val);
}

var onDebouncedDrawer = debounce(function (val) {
    if (expandDetails.value) {
        if (debouncedDrawer.value === false) {
            expandDetails.value = false;
        }
    }
}, 250);

const setAgentDetailsStyleByViewport = (viewport) => {
    const agentDetailsSection = document.querySelector(
        ".prospus-expanded-details",
    );
    if (viewport <= 1024) {
        if (agentDetailsSection) {
            agentDetailsSection.style.webkitAnimation = "none";
            agentDetailsSection.style.animation = "none";

            if (viewport <= 449) {
                agentDetailsSection.style.width = `${viewport - 24}px`;
                agentDetailsSection.style.right = `calc(-${viewport}px + 24px)`;
            } else {
                agentDetailsSection.style.width = "436px";
                agentDetailsSection.style.right = `-436px`;
            }
        }
    } else {
        if (agentDetailsSection) {
            agentDetailsSection.style.webkitAnimation = "";
            agentDetailsSection.style.animation = "";
            agentDetailsSection.style.right = "";
            agentDetailsSection.style.width = "";
        }
    }
};

watch(screenSize, setAgentDetailsStyleByViewport);

onMounted(() => {
    screenSize.value = innerWidth;
    setAgentDetailsStyleByViewport(screenSize.value);

    window.onresize = () => {
        screenSize.value = innerWidth;
    };
});

function handleSignInAndToggleView() {
    emitter("sign-in");
    toggleView();
}

function handleSignUpAndToggleView() {
    emitter("sign-up");
    toggleView();
}
</script>

<style scoped>
#pseudo-click-layer {
    height: 100vh;
    width: 80vw;
    background: transparent;
    position: fixed;
    top: 0;
    z-index: -2;
    left: 0;
    display: none;
}

#chat-app-header-mobile {
    display: none !important;
}

@media (max-width: 1024px) {
    #chat-app-header-desktop {
        display: none !important;
    }

    #chat-app-header-mobile {
        display: flex !important;
    }
}
</style>
